// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck projectActions properties generated at runtime
import { buildInteractor, buildInteractorDirectAction, buildInteractorDirectActionNoParams } from './base';
import { registerActions } from '../slices/registerSlice';
import userService from '../../services/UserServices';

export const updateRegisterInInteractor = buildInteractorDirectAction(registerActions.updateRegisterIn);
export const updateRegisterOutInteractor = buildInteractorDirectAction(registerActions.updateRegisterOut);
export const resetRegisterInInteractor = buildInteractorDirectActionNoParams(registerActions.resetRegisterIn);
export const resetRegisterOutInteractor = buildInteractorDirectActionNoParams(registerActions.resetRegisterOut);
export const postRegisterInInteractor = buildInteractor(
  registerActions.loadingPostRegisterIn,
  registerActions.successPostRegisterIn,
  registerActions.errorPostRegisterIn,
  userService.registerInUser,
);
export const postRegisterOutInteractor = buildInteractor(
  registerActions.loadingPostRegisterOut,
  registerActions.successPostRegisterOut,
  registerActions.errorPostRegisterOut,
  userService.registerOutUser,
);
