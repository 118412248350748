import { UserState, Saved } from '../types/userTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from './base';

const initialState: UserState = {
  // pass
  offline: true,
};

const removeObjectWithId = (arr: Saved[], id?: number) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
  arr.splice(objWithIdIndex, 1);
  return arr;
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state: UserState, action: Action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    logout: () => {
      return {
        ...initialState,
      };
    },
    profile: (state: UserState, action: Action) => {
      return {
        ...state,
        business: action.payload.value.business,
        saveds: action.payload.value.saveds,
        registerOuts: action.payload.value.registerOuts,
      };
    },
    saveProtocol: (state: UserState, action: Action) => {
      state.saveds ? state.saveds.push(action.payload.value) : [...action.payload.value];
    },
    unsaveProtocol: (state: UserState, action: Action) => {
      removeObjectWithId(state.saveds ? state.saveds : [], action.payload.value);
    },
    redirect: (state: UserState, action: Action) => {
      return {
        ...state,
        redirect: action.payload.redirect,
      };
    },
    setMode: (state: UserState, action: Action) => {
      return {
        ...state,
        mode: action.payload.mode,
      };
    },
    setStarted: (state: UserState, action: Action) => {
      return {
        ...state,
        started: action.payload.started,
      };
    },
    setOffline: (state: UserState) => {
      return {
        ...state,
      };
    },
  },
});

export const userActions = UserSlice.actions;
export const userReducer = UserSlice.reducer;
