import React, { FC } from 'react';
import { Box } from '@mui/material';
import Protocol from '../components/OtherComponents/Protocol';
import PopUpMenu from '../components/BodyScreenComponents/PopUpMenu';

const RoutineScreen: FC = () => {
  return (
    <Box sx={{ width: '100vw', height: '100%', minHeight: '100vh', paddingTop: '60px' }}>
      <Protocol />
      <PopUpMenu />
    </Box>
  );
};

export default RoutineScreen;
