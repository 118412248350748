import Dexie from 'dexie';
import axios from 'axios';
import { gifs } from './gif';
// import { webps } from './webp';

// Definir el esquema de la base de datos
const db = new Dexie('imagesDB');

db.version(1).stores({
  imagenes: '++id,url,blob',
});

// Función para agregar una imagen a la base de datos
export interface Image {
  id?: number;
  url: string;
  blob: Blob;
}

export interface Database extends Dexie {
  imagenes: Dexie.Table<Image, number>;
}

export const saveImage = async (url: string) => {
  try {
    const imagenExistente = await (db as Database).imagenes.where({ url }).first();
    if (imagenExistente) {
      //console.log(`${url} ya existe en la base de datos.`);
      return;
    }
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    // Descargar la imagen y convertirla en un blob
    const blob = new Blob([response.data], { type: 'image/webp' });

    // Almacenar la imagen en la base de datos
    await (db as Database).imagenes.add({ url, blob });
    console.log('Imagen agregada correctamente.');
  } catch (error) {
    console.error('Error al agregar la imagen:', error, url);
  }
};

export const isSafari = () => {
  // Obtener la cadena del agente del usuario del navegador
  const userAgent = navigator.userAgent;

  // Verificar si la cadena del agente del usuario contiene la palabra "Safari"
  return userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1;
};

export const preloadImages = () => {
  gifs.forEach((url) => saveImage(url));
};

export default db;
