import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ProtocolCard from '../OtherComponents/Card';
import { Box, Fade } from '@mui/material';
import AssistanceCard from '../OtherComponents/AssistanceCard';

interface Props {
  specs: {
    name: string;
    protocolIds?: number[];
  };
}

const unsafeProtocols = [5, 9, 12, 18, 19, 20, 21, 22, 23, 25];

const MenuCategory: FC<Props> = ({ specs }: Props) => {
  const protocols = useSelector((state: RootState) => state.protocol.protocols);
  const { diabetes, thrombosis, heart_diseases } = useSelector((state: RootState) => state.user);
  let filteredProtocolIds = useSelector((state: RootState) => state.filter.filteredProtocolIds).concat(0);
  if (diabetes === true || thrombosis === true || heart_diseases === true) {
    filteredProtocolIds = filteredProtocolIds.filter((id) => !unsafeProtocols.includes(id));
  }
  let category_protocols = protocols.filter((protocol) => specs.protocolIds?.includes(+protocol.id));
  category_protocols = category_protocols.filter((protocol) => filteredProtocolIds.includes(+protocol.id));
  return (
    <Fade in={category_protocols.length > 0}>
      <Box
        alignItems="stretch"
        sx={{
          textAlign: 'left',
          margin: '0',
          padding: '0',
        }}>
        <Box sx={{ marginX: '5vw', marginY: '0', paddingY: '0' }}>
          <h2 className="App-logo-text">{specs.name}</h2>
        </Box>
        <Box
          sx={{
            marginY: '0',
            paddingX: '10vw',
            paddingY: '0',
            display: 'flex',
            gap: 1,
            py: 1,
            overflow: 'auto',
            width: '100vw',
            scrollSnapType: 'x mandatory',
            '& > *': {
              scrollSnapAlign: 'center',
            },
            '::-webkit-scrollbar': { display: 'none' },
          }}>
          {category_protocols?.map((prot, index) =>
            prot.id === 0 ? (
              <AssistanceCard key={`${specs.name.substring(0, 10)}prot${prot.id}-${index}`} />
            ) : (
              <ProtocolCard specs={prot} key={`${specs.name.substring(0, 10)}prot${prot.id}-${index}`} />
            ),
          )}
        </Box>
      </Box>
    </Fade>
  );
};

export default MenuCategory;
