import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import db, { Database } from '../../database/db';
import { Button } from '@mui/material';
import { businessActions } from '../../redux/slices/businessSlice';
import { useDispatch } from 'react-redux';
import { version } from '../../appVersion';

const OfflineToggleButton: FC = () => {
  const [imgCount, setImgCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();

  const count = () => {
    if (counter < 3) {
      setCounter(counter + 1);
    } else {
      setCounter(0);
    }
  };

  const logout = () => {
    dispatch(businessActions.logout());
  };

  useEffect(() => {
    const getImagesCount = async () => {
      const count = await (db as Database).imagenes.count();
      setImgCount(count);
    };

    getImagesCount();
  }, []);

  return (
    <Box sx={{ padding: counter >= 3 ? '200px' : '20px', paddingBottom: '100px', width: '100%' }}>
      {version}
      <Button onClick={count} sx={{ width: '100%', height: '20px' }}></Button>
      {counter >= 3 && (
        <Box>
          <Box sx={{ textAlign: 'center', fontSize: '10pt' }}>
            {Math.floor((imgCount / 51) * 100)}% descargado Modo Offline, {imgCount} archivos descargados
          </Box>
          <Button
            sx={{ margin: 'auto', display: 'block', marginTop: '20px' }}
            variant="contained"
            color="primary"
            onClick={logout}>
            Desactivar totem
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default OfflineToggleButton;
