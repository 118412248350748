import React, { FC } from 'react';
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';

const ConsoltoButton: FC = () => {
  return (
    <Button
      variant="contained"
      href="https://api.whatsapp.com/send/?phone=+56931107304&text&type=phone_number&app_absent=0"
      startIcon={<CallIcon />}
      sx={{
        backgroundImage: 'linear-gradient(to bottom right,#0068ff, #7be7c6)',
        fontWeight: 'bold',
        width: '100%',
        borderRadius: '50px',
      }}>
      ASISTENCIA EN LINEA
    </Button>
  );
};

export default ConsoltoButton;
