import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import * as NordicFormInteractors from '../../../redux/interactors/NordicFormInteractors';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../redux/store';
import { useSelector, connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { es } from '../../../language/es';

interface Checked {
  cuello: boolean;
  hombro: boolean;
  brazoCodo: boolean;
  manoMuñeca: boolean;
  espaldaAlta: boolean;
  espaldaBaja: boolean;
  caderaPierna: boolean;
  rodilla: boolean;
  tobilloPie: boolean;
  [index: string]: any;
}

interface StateProps {
  name: string;
  tag: string;
  onChange: (e: boolean) => void;
}

interface DispatchProps {
  updateFirstCheckInteractor: typeof NordicFormInteractors.updateFirstCheckInteractor;
  updateWeekCheckInteractor: typeof NordicFormInteractors.updateWeekCheckInteractor;
}

interface Props extends StateProps, DispatchProps {
  // extra props you want to add
}

const NordicFormBody: FC<Props> = (props: Props) => {
  const { tag, name } = props;
  useEffect(() => {
    props.onChange(true);
  }, []);
  const [checked, setChecked] = useState<Checked>({
    cuello: false,
    hombro: false,
    brazoCodo: false,
    manoMuñeca: false,
    espaldaAlta: false,
    espaldaBaja: false,
    caderaPierna: false,
    rodilla: false,
    tobilloPie: false,
  });
  const enabled =
    name === 'firstCheck'
      ? {
          cuello: true,
          hombro: true,
          brazoCodo: true,
          manoMuñeca: true,
          espaldaAlta: true,
          espaldaBaja: true,
          caderaPierna: true,
          rodilla: true,
          tobilloPie: true,
        }
      : useSelector((state: RootState) => state.nordicForm.firstCheck);
  const baseColor = '#9f9f9f';
  const selectedColor = '#2468e5';
  const [colorCuello, setColorCuello] = useState(baseColor);
  const [colorHombro, setColorHombro] = useState(baseColor);
  const [colorBrazoCodo, setColorBrazoCodo] = useState(baseColor);
  const [colorManoMuñeca, setColorManoMuñeca] = useState(baseColor);
  const [colorEspaldaAlta, setColorEspaldaAlta] = useState(baseColor);
  const [colorEspaldaBaja, setColorEspaldaBaja] = useState(baseColor);
  const [colorCaderaPierna, setColorCaderaPierna] = useState(baseColor);
  const [colorRodilla, setColorRodilla] = useState(baseColor);
  const [colorTobilloPie, setColorTobilloPie] = useState(baseColor);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = checked;
    if (newChecked[event.target.name] !== undefined) {
      newChecked[event.target.name] = event.target.checked;
    }
    setChecked(newChecked);
    setColorCuello(checked.cuello === true ? selectedColor : baseColor);
    setColorHombro(checked.hombro === true ? selectedColor : baseColor);
    setColorBrazoCodo(checked.brazoCodo === true ? selectedColor : baseColor);
    setColorManoMuñeca(checked.manoMuñeca === true ? selectedColor : baseColor);
    setColorEspaldaAlta(checked.espaldaAlta === true ? selectedColor : baseColor);
    setColorEspaldaBaja(checked.espaldaBaja === true ? selectedColor : baseColor);
    setColorCaderaPierna(checked.caderaPierna === true ? selectedColor : baseColor);
    setColorRodilla(checked.rodilla === true ? selectedColor : baseColor);
    setColorTobilloPie(checked.tobilloPie === true ? selectedColor : baseColor);
    if (name === 'firstCheck') {
      props.updateFirstCheckInteractor(checked);
    } else {
      props.updateWeekCheckInteractor(checked);
    }
    props.onChange(true);
  };

  return (
    <Box sx={{ display: 'flex', height: '90%', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
      <Box sx={{ width: '48%', maxWidth: '200px' }}>
        <svg className="disbled" width="100%" height="100%" viewBox="0 0 230.000000 680.000000">
          <g transform="translate(0.000000,680.000000) scale(0.100000,-0.100000)">
            <g id="head" fill="#9f9f9f">
              <path d="M1025 6696 c-75 -35 -140 -109 -154 -176 -13 -60 -6 -217 12 -260 5 -14 11 -29 13 -35 27 -90 96 -234 126 -262 13 -11 46 -35 74 -51 l52 -30 48 28 c82 48 111 80 139 155 15 39 29 88 31 109 3 22 12 52 19 67 15 28 20 60 30 187 7 88 -12 138 -77 205 -68 70 -88 79 -180 83 -71 3 -90 0 -133 -20z" />
              <path d="M825 6218 c17 -107 11 -95 53 -107 l30 -9 -10 37 c-6 20 -15 42 -20 49 -6 6 -7 12 -3 12 5 0 0 9 -10 20 -24 27 -44 26 -40 -2z" />
              <path d="M1411 6217 c-21 -54 -34 -108 -28 -113 4 -4 17 0 30 8 16 11 24 27 25 53 2 21 3 40 4 44 4 36 -18 42 -31 8z" />
            </g>
            <g style={{ fill: colorCuello }}>
              <path d="M1245 5907 l-70 -43 3 -45 c1 -24 8 -48 15 -54 34 -28 50 -70 51 -137 l1 -68 67 0 c65 0 257 29 330 50 38 11 67 45 51 60 -4 4 -66 27 -138 50 -127 41 -206 78 -222 103 -4 6 -10 38 -13 70 l-5 58 -70 -44z" />
              <path d="M978 5934 c-3 -3 -2 -22 3 -42 16 -71 -18 -94 -244 -168 -163 -53 -162 -52 -110 -121 22 -28 32 -32 106 -43 45 -6 134 -10 197 -8 l114 3 3 50 c3 61 29 135 55 155 20 17 34 78 21 96 -10 13 -120 84 -132 84 -4 0 -10 -2 -13 -6z" />
            </g>
            <g>
              <path d="M1102 5713 c-9 -19 -8 -178 2 -563 4 -129 9 -343 11 -475 9 -550 17 -800 25 -808 21 -23 22 -11 34 628 3 165 8 365 11 445 25 683 25 718 9 757 -12 28 -19 33 -49 33 -23 0 -37 -6 -43 -17z" />
            </g>
            <g style={{ fill: colorHombro }}>
              <path d="M495 5597 c-22 -12 -76 -57 -120 -99 -67 -64 -84 -88 -107 -145 -27 -68 -47 -177 -29 -158 5 6 43 60 84 122 80 119 203 273 218 273 20 0 6 -32 -39 -92 -112 -146 -232 -323 -232 -341 0 -5 16 0 35 12 19 11 73 37 120 57 85 36 127 72 199 167 l29 38 -21 42 c-45 85 -79 141 -88 143 -5 1 -27 -8 -49 -19z" />
            </g>
            <g style={{ fill: colorHombro }}>
              <path d="M1725 5580 c-14 -22 -25 -44 -25 -50 0 -5 -4 -10 -10 -10 -5 0 -10 -6 -10 -14 0 -8 -10 -27 -22 -43 -54 -67 -54 -66 16 -128 72 -64 90 -74 196 -117 41 -17 85 -37 97 -45 33 -22 29 -6 -19 89 -48 97 -111 189 -162 237 -34 33 -47 61 -27 61 6 0 37 -25 71 -56 65 -61 116 -138 186 -288 16 -32 29 -54 31 -50 10 39 7 95 -8 132 -10 23 -16 42 -13 42 2 0 -4 17 -15 39 -28 55 -142 166 -221 216 l-40 25 -25 -40z" />
            </g>
            <g style={{ fill: colorEspaldaAlta }}>
              <path d="M1565 5559 c-89 -24 -234 -41 -283 -33 l-37 6 -2 -99 c0 -54 -3 -223 -6 -376 -4 -192 -2 -277 5 -275 9 3 48 96 63 148 25 88 101 240 175 350 28 41 137 230 133 230 -2 0 5 12 16 26 21 27 27 45 14 43 -5 -1 -39 -10 -78 -20z" />
              <path d="M680 5509 c7 -13 24 -39 38 -56 13 -18 20 -33 16 -33 -5 0 -4 -4 2 -8 10 -6 31 -41 100 -164 7 -11 17 -25 23 -31 6 -6 11 -17 11 -24 0 -7 16 -35 35 -62 19 -26 35 -52 35 -57 0 -5 16 -50 35 -99 20 -50 37 -97 39 -105 2 -8 12 -35 23 -59 l19 -45 -3 360 c-3 197 -6 364 -8 370 -2 7 -48 13 -117 17 -62 4 -146 10 -187 13 l-73 7 12 -24z" />
              <path d="M648 5373 c-9 -16 -35 -47 -58 -70 l-42 -42 22 -43 c41 -79 80 -158 80 -163 1 -2 8 -22 17 -42 9 -21 17 -47 19 -58 2 -11 8 -38 13 -60 20 -84 16 -512 -5 -546 -2 -4 -6 -27 -8 -51 -3 -23 -8 -51 -11 -60 -6 -15 -1 -18 31 -18 46 0 50 5 144 185 35 66 85 161 112 211 26 51 48 105 48 120 0 26 -88 265 -111 304 -5 8 -14 24 -19 35 -6 11 -18 32 -28 47 -9 15 -44 71 -77 125 -67 111 -96 153 -105 153 -3 0 -13 -12 -22 -27z" />
              <path d="M1559 5307 c-19 -29 -36 -56 -38 -62 -2 -5 -32 -55 -66 -110 -66 -107 -107 -190 -97 -199 3 -3 0 -6 -6 -6 -7 0 -12 -8 -12 -17 0 -10 -14 -49 -30 -88 -17 -38 -33 -92 -36 -120 -6 -48 -2 -58 107 -278 l113 -227 39 2 c55 4 64 22 46 95 -15 61 -18 220 -7 313 14 117 18 157 22 230 3 41 7 86 11 100 3 14 7 36 10 50 3 23 27 71 91 188 13 23 24 46 24 50 0 4 -31 35 -69 69 l-69 62 -33 -52z" />
            </g>
            <g style={{ fill: colorBrazoCodo }}>
              <path d="M410 5181 c-42 -18 -87 -49 -131 -89 -100 -92 -110 -134 -95 -396 3 -43 7 -80 9 -83 3 -2 19 3 36 11 16 9 37 16 45 16 23 0 72 -32 96 -63 29 -36 36 -34 34 11 0 20 2 42 5 47 3 6 6 15 7 20 1 6 6 21 12 35 5 14 11 30 12 35 1 6 10 26 20 45 9 19 25 58 34 85 9 28 32 68 51 90 20 22 38 49 41 60 4 18 -27 97 -71 175 -18 33 -28 33 -105 1z" />
            </g>
            <g style={{ fill: colorBrazoCodo }}>
              <path d="M1763 5147 c-55 -106 -77 -164 -63 -169 17 -6 100 -166 100 -193 0 -7 5 -17 10 -20 6 -3 10 -15 10 -26 0 -10 16 -50 36 -89 33 -66 37 -70 62 -63 15 5 60 8 100 7 85 -1 79 -10 88 141 8 138 6 166 -16 230 -15 43 -33 70 -77 111 -64 61 -91 77 -170 105 l-53 18 -27 -52z" />
            </g>
            <g style={{ fill: colorEspaldaBaja }}>
              <path d="M993 4558 c-38 -57 -95 -150 -126 -208 -50 -96 -61 -130 -58 -184 1 -18 204 -225 260 -265 l29 -21 -5 73 c-3 39 -7 211 -9 381 -2 170 -7 313 -12 318 -4 4 -40 -38 -79 -94z" />
              <path d="M1199 4353 c-1 -170 -3 -337 -3 -373 -1 -36 -1 -68 1 -71 1 -4 57 46 123 110 154 149 153 139 38 359 -44 86 -95 185 -112 219 -17 34 -34 62 -38 62 -5 1 -9 -137 -9 -306z" />
              <path d="M735 4068 c-14 -11 -34 -37 -45 -58 -11 -21 -43 -73 -73 -116 -60 -87 -90 -141 -94 -164 -1 -8 -5 -37 -9 -65 -4 -27 -7 -77 -7 -110 -1 -53 3 -87 18 -175 2 -14 7 -49 10 -78 7 -69 41 -148 74 -171 14 -11 48 -25 75 -33 46 -14 55 -13 125 7 75 22 104 33 211 82 49 22 58 24 92 12 29 -9 47 -10 76 -1 36 11 47 8 148 -32 232 -93 306 -86 380 41 27 44 31 61 39 139 2 28 8 62 13 75 5 13 8 31 6 39 -1 8 -5 33 -8 55 -4 22 -10 51 -13 65 -7 25 -8 32 -16 80 -10 61 -51 150 -92 200 -20 25 -59 80 -87 124 l-51 78 -41 -6 c-33 -6 -54 -20 -106 -70 -36 -35 -94 -87 -129 -116 -57 -46 -69 -51 -99 -46 -21 3 -51 21 -80 48 -26 24 -92 77 -147 118 -55 41 -102 77 -105 80 -14 18 -41 17 -65 -2z" />
              <g>
                <path d="M646 4163 c-26 -92 -46 -193 -37 -193 9 0 121 191 121 208 0 1 -18 2 -39 2 -29 0 -41 -5 -45 -17z" />
                <path d="M1530 4141 c0 -22 85 -188 107 -211 14 -13 15 -9 9 35 -4 28 -11 73 -17 100 -6 28 -12 60 -15 73 -4 18 -11 22 -44 22 -33 0 -40 -4 -40 -19z" />
              </g>
            </g>
            <g style={{ fill: colorManoMuñeca }}>
              <path d="M244 4590 c-40 -18 -74 -70 -89 -135 -4 -16 -9 -39 -11 -50 -3 -11 -7 -29 -9 -40 -2 -11 -10 -45 -18 -75 -9 -30 -13 -58 -10 -62 2 -5 6 -70 9 -146 2 -75 6 -146 8 -157 3 -11 5 -52 5 -92 l1 -72 43 -26 c28 -15 59 -25 84 -25 45 0 52 9 59 70 3 19 11 55 19 80 8 25 17 56 19 70 10 45 36 165 41 185 3 11 7 29 9 40 2 11 9 40 16 65 6 25 11 50 11 55 0 17 -8 79 -15 110 -3 17 -8 39 -10 51 -6 31 -61 116 -94 146 -29 26 -30 26 -68 8z" />
              <path d="M113 3678 c-44 -76 -62 -115 -67 -148 -4 -19 -8 -39 -10 -45 -2 -5 -7 -29 -11 -53 -4 -23 -12 -45 -16 -48 -15 -9 -10 -54 5 -54 15 0 19 10 32 70 13 68 22 82 43 70 21 -11 22 -20 6 -129 -6 -41 -5 -55 6 -62 18 -11 38 25 39 67 0 66 11 94 36 94 22 0 23 -1 18 -104 -4 -91 -2 -105 12 -110 28 -11 35 7 39 90 2 44 3 85 4 92 1 6 10 12 20 12 15 0 21 -9 25 -37 2 -21 5 -45 5 -53 -1 -47 9 -93 21 -100 8 -5 16 -8 17 -6 2 1 5 72 7 157 3 85 5 157 6 162 1 21 33 1 73 -45 24 -28 49 -48 55 -44 10 6 8 15 -24 101 -4 11 -6 23 -6 28 1 4 -29 28 -67 52 -64 41 -75 45 -134 45 -45 0 -68 5 -77 15 -19 23 -37 18 -57 -17z" />
            </g>
            <g style={{ fill: colorManoMuñeca }}>
              <path d="M1935 4544 c-59 -30 -59 -30 -69 -199 -2 -38 -5 -74 -7 -80 -2 -5 2 -32 9 -60 7 -27 15 -61 18 -75 9 -41 12 -55 15 -55 1 0 3 -9 5 -20 6 -38 15 -80 24 -110 5 -16 16 -64 25 -105 19 -89 23 -104 25 -107 2 -2 3 -9 4 -15 3 -31 106 -20 148 15 27 22 27 24 33 182 10 232 10 335 1 380 -4 22 -8 49 -10 60 -2 11 -5 21 -6 23 -3 2 -3 4 -14 52 -17 78 -33 98 -90 114 -67 20 -74 20 -111 0z" />
              <path d="M2124 3685 c-11 -9 -42 -14 -77 -15 -50 0 -69 -6 -125 -39 -62 -36 -69 -44 -89 -98 -23 -59 -29 -93 -17 -93 4 0 28 24 54 52 41 45 50 51 63 40 13 -11 15 -38 13 -168 -2 -120 0 -154 10 -154 18 0 31 28 33 70 3 49 8 114 10 123 3 16 41 6 42 -10 1 -24 7 -88 15 -141 4 -36 8 -41 22 -33 14 8 17 26 17 108 0 94 1 98 22 101 22 3 30 -12 32 -58 2 -42 11 -94 18 -101 5 -5 14 -5 21 0 9 5 10 20 4 52 -4 24 -8 64 -7 89 0 39 3 45 23 48 13 2 22 -2 23 -10 0 -7 3 -22 6 -33 3 -11 7 -37 10 -57 3 -23 10 -38 19 -38 13 0 19 14 14 35 -1 6 -4 21 -6 35 -3 14 -7 33 -10 42 -5 16 -10 40 -20 88 -9 42 -81 180 -94 180 -4 0 -15 -7 -26 -15z" />
            </g>
            <g style={{ fill: colorCaderaPierna }}>
              <path d="M1174 3148 c-11 -32 -6 -283 6 -347 5 -30 10 -75 10 -100 0 -26 11 -90 25 -142 14 -51 27 -101 30 -109 2 -8 17 -55 35 -103 21 -60 30 -103 29 -136 -3 -56 2 -67 36 -73 30 -6 31 -5 39 47 4 22 13 81 21 130 8 50 19 126 26 170 6 44 12 87 14 95 3 17 13 77 21 134 3 21 8 48 10 60 2 11 9 69 16 128 11 102 11 109 -8 134 -11 16 -48 38 -89 54 -39 15 -99 40 -134 54 -78 32 -78 31 -87 4z" />
              <path d="M1674 3101 c-20 -18 -48 -31 -78 -35 -53 -8 -53 -12 10 -78 22 -24 53 -63 68 -86 15 -24 29 -42 31 -40 8 8 15 268 8 268 -4 0 -22 -13 -39 -29z" />
              <path d="M1527 2853 c-10 -65 -24 -160 -32 -211 -48 -315 -67 -492 -55 -507 32 -39 97 2 140 88 28 57 32 75 32 153 1 84 7 115 44 241 46 153 34 228 -47 301 -29 26 -55 48 -58 50 -4 1 -15 -51 -24 -115z" />
              <path d="M1473 1902 c-13 -8 -31 -84 -38 -157 -2 -22 -8 -87 -13 -145 -10 -108 -17 -326 -16 -510 0 -97 2 -110 33 -172 30 -63 51 -86 51 -55 0 6 7 25 15 41 9 16 13 31 11 33 -2 3 2 19 9 37 8 18 16 44 19 57 3 13 17 65 31 114 48 167 53 190 56 245 2 50 -8 186 -17 235 -9 46 -22 128 -28 181 -9 76 -67 125 -113 96z" />
              <path d="M1312 1873 c-8 -16 -19 -52 -27 -93 -2 -8 -9 -35 -16 -60 -7 -25 -13 -49 -15 -55 -24 -98 -30 -133 -28 -150 6 -39 18 -195 28 -365 10 -158 18 -255 22 -259 8 -8 46 34 65 72 24 46 29 128 33 534 3 352 0 387 -31 391 -13 2 -25 -4 -31 -15z" />
              <path d="M1358 929 c-10 -6 -18 -15 -18 -21 0 -6 -14 -23 -32 -38 -30 -25 -31 -29 -29 -91 5 -128 9 -171 17 -179 5 -4 30 -14 56 -23 40 -13 49 -13 59 -1 6 8 9 14 6 14 -3 0 2 21 10 48 13 43 20 69 26 97 2 6 6 15 10 21 6 10 -62 157 -81 176 -4 4 -15 2 -24 -3z" />
            </g>
            <g style={{ fill: colorCaderaPierna }}>
              <path d="M980 3112 c-118 -45 -199 -91 -201 -116 -4 -34 4 -129 16 -186 7 -36 19 -120 25 -188 6 -67 15 -135 20 -150 5 -15 12 -63 16 -107 4 -44 8 -87 9 -95 1 -8 5 -44 10 -80 4 -36 12 -71 17 -77 17 -21 46 -15 76 15 40 39 51 70 39 109 -8 24 -7 44 4 75 8 24 13 46 11 51 -1 4 3 7 10 7 6 0 9 3 5 6 -3 3 -1 18 5 33 12 32 67 274 80 356 8 55 12 171 9 333 -1 42 -5 62 -13 62 -7 0 -69 -22 -138 -48z" />
              <path d="M534 3134 c16 -43 46 -177 46 -208 0 -24 5 -36 14 -36 17 0 79 68 101 111 19 36 10 49 -34 49 -17 0 -40 17 -71 50 -47 51 -67 63 -56 34z" />
              <path d="M658 2891 c-59 -63 -71 -98 -49 -139 5 -9 12 -33 15 -52 7 -39 10 -48 19 -70 4 -8 14 -40 22 -70 8 -30 18 -64 22 -75 5 -18 7 -99 3 -177 -1 -27 25 -85 68 -150 27 -41 67 -52 79 -21 10 25 -51 432 -115 777 l-7 39 -57 -62z" />
              <path d="M735 1876 c-8 -19 -17 -63 -21 -97 -3 -35 -10 -74 -15 -86 -5 -13 -6 -23 -3 -23 2 0 1 -8 -5 -17 -8 -16 -16 -67 -26 -176 -4 -43 8 -142 25 -202 6 -22 13 -47 15 -55 2 -8 4 -15 5 -15 1 0 3 -11 6 -25 2 -14 8 -36 13 -50 8 -19 29 -89 46 -150 20 -70 27 -90 35 -95 11 -7 54 58 65 100 5 17 8 136 7 265 -1 208 -6 288 -28 547 -7 76 -31 113 -75 113 -25 0 -33 -6 -44 -34z" />
              <path d="M916 1863 c-3 -16 -2 -210 3 -433 8 -372 10 -409 29 -460 19 -52 64 -101 78 -87 4 4 8 37 9 74 2 38 5 104 9 148 4 44 9 109 11 145 3 36 8 94 12 130 9 93 6 178 -7 230 -6 25 -14 56 -17 70 -9 43 -11 55 -13 55 -1 0 -4 11 -7 25 -2 14 -5 30 -7 35 -1 6 -12 29 -26 53 -29 51 -65 59 -74 15z" />
              <path d="M854 878 l-37 -52 17 -50 c10 -27 20 -61 22 -75 3 -14 10 -42 16 -61 7 -19 12 -39 13 -45 1 -23 93 -16 118 9 12 13 17 58 19 171 1 58 -2 75 -15 75 -7 0 -31 18 -52 40 -21 22 -44 40 -51 40 -7 0 -29 -23 -50 -52z" />
            </g>
            <g style={{ fill: colorRodilla }}>
              <path d="M1556 2131 c-16 -17 -51 -35 -86 -46 -40 -12 -60 -24 -61 -34 -1 -19 -5 -45 -14 -78 -5 -22 -3 -23 55 -23 33 0 78 -7 100 -16 23 -9 43 -14 46 -11 6 6 7 31 5 155 0 45 -5 82 -9 82 -5 0 -21 -13 -36 -29z" />
              <path d="M1262 2130 c-5 -13 -6 -39 -2 -57 4 -17 7 -76 6 -130 -2 -76 0 -91 7 -65 6 17 14 32 18 32 5 0 20 18 34 40 38 60 34 104 -15 160 -39 45 -39 45 -48 20z" />
            </g>
            <g style={{ fill: colorRodilla }}>
              <path d="M699 2166 c-4 -4 -3 -155 1 -238 0 -14 21 -9 46 11 18 14 34 18 64 14 63 -9 71 -2 56 49 -11 36 -20 46 -60 66 -25 13 -46 29 -46 36 0 14 -54 69 -61 62z" />
              <path d="M966 2080 c-59 -63 -61 -95 -11 -139 20 -17 42 -31 50 -31 8 0 19 -15 24 -32 7 -26 8 -14 7 52 -2 47 1 100 5 118 4 17 3 35 -2 38 -5 3 -9 14 -9 25 0 30 -13 23 -64 -31z" />
            </g>
            <g style={{ fill: colorTobilloPie }}>
              <path d="M950 546 c-35 -13 -35 -13 -36 -77 0 -35 -6 -81 -12 -102 -8 -28 -8 -45 0 -62 9 -20 6 -30 -19 -67 -24 -35 -28 -49 -22 -77 4 -18 13 -37 21 -42 7 -5 48 -9 91 -9 73 0 79 2 107 30 17 17 30 38 31 48 0 9 2 25 4 34 3 17 -40 118 -54 128 -25 16 -41 86 -44 188 -1 25 -15 27 -67 8z" />
            </g>
            <g style={{ fill: colorTobilloPie }}>
              <path d="M1284 528 c-2 -101 -23 -168 -67 -216 -16 -17 -21 -37 -23 -86 -1 -60 0 -67 28 -91 28 -26 35 -27 118 -23 49 1 91 6 94 9 11 11 14 63 5 84 -5 11 -18 34 -28 50 -11 18 -16 38 -11 50 3 11 5 29 3 40 -2 11 -7 58 -11 104 l-7 84 -35 13 c-56 20 -65 18 -66 -18z" />
            </g>
          </g>
        </svg>
      </Box>
      <Box sx={{ width: '50%', maxWidth: '200px', margin: '5px 0 0 5px' }}>
        <Typography>
          {es.nordic.body.question} <b>{tag}</b>
        </Typography>
        <FormGroup>
          {enabled.cuello === true && (
            <FormControlLabel
              control={<Checkbox name="cuello" onChange={handleChange} />}
              label={es.nordic.body.cuello}
            />
          )}
          {enabled.hombro === true && (
            <FormControlLabel
              control={<Checkbox name="hombro" onChange={handleChange} />}
              label={es.nordic.body.hombro}
            />
          )}
          {enabled.brazoCodo === true && (
            <FormControlLabel
              control={<Checkbox name="brazoCodo" onChange={handleChange} />}
              label={es.nordic.body.brazoCodo}
            />
          )}
          {enabled.manoMuñeca === true && (
            <FormControlLabel
              control={<Checkbox name="manoMuñeca" onChange={handleChange} />}
              label={es.nordic.body.manoMuñeca}
            />
          )}
          {enabled.espaldaAlta === true && (
            <FormControlLabel
              control={<Checkbox name="espaldaAlta" onChange={handleChange} />}
              label={es.nordic.body.espaldaAlta}
            />
          )}
          {enabled.espaldaBaja === true && (
            <FormControlLabel
              control={<Checkbox name="espaldaBaja" onChange={handleChange} />}
              label={es.nordic.body.espaldaBaja}
            />
          )}
          {enabled.caderaPierna === true && (
            <FormControlLabel
              control={<Checkbox name="caderaPierna" onChange={handleChange} />}
              label={es.nordic.body.caderaPierna}
            />
          )}
          {enabled.rodilla === true && (
            <FormControlLabel
              control={<Checkbox name="rodilla" onChange={handleChange} />}
              label={es.nordic.body.rodilla}
            />
          )}
          {enabled.tobilloPie === true && (
            <FormControlLabel
              control={<Checkbox name="tobilloPie" onChange={handleChange} />}
              label={es.nordic.body.tobilloPie}
            />
          )}
        </FormGroup>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...NordicFormInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(NordicFormBody);
