import React, { FC } from 'react';
import { Box } from '@mui/material';
import BodyBackMap from './BodyBackMap';

const BodyMap: FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'inline-block', minWidth: '140px', height: '75vh', marginY: '0px', paddingY: '0px' }}>
        <BodyBackMap />
      </Box>
    </Box>
  );
};

export default BodyMap;
