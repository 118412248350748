import React, { FC, Fragment } from 'react';
import { Card, CardMedia, CardActions, CardContent } from '@mui/material';
import { Button, Fade } from '@mui/material';
import stock_2 from './../../images/stock_2.png';
import stock_3 from './../../images/stock_3.png';

const AssistanceCard: FC = () => {
  return (
    <Fade in={true}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '10px',
          minWidth: '300px',
          maxWidth: '300px',
          width: '300px',
          borderRadius: '20px',
          paddingBottom: '12px',
          marginBottom: '25px',
          background: 'white',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          position: 'relative',
          top: '0',
          zIndex: '500',
        }}>
        <Fragment>
          <CardMedia
            sx={{
              padding: '0px',
              marginBottom: '0',
            }}
            component="img"
            height="174"
            width="100%"
            alt="default image"
            image="https://cdn.makana.cl/mkn-601.jpg"
          />
          <img className="hover_slide_right" src={stock_3 ? stock_3 : stock_2} />
          <CardContent
            sx={{
              zIndex: '3',
              position: 'absolute',
              top: '120px',
              right: '0px',
              paddingY: '10px',
              background: 'transparent',
            }}></CardContent>
          <CardContent
            sx={{
              marginTop: '-25px',
              paddingTop: '10px',
              paddingBottom: '50px',
              background: 'white',
              borderRadius: '20px 20px 0 0',
              zIndex: '2',
            }}>
            <h4 className="App-card-text">Asistencia en Linea</h4>
          </CardContent>
          <CardActions sx={{ zIndex: '2', position: 'absolute', bottom: '10px', paddingY: '0px', width: '100%' }}>
            <Button
              sx={{ width: '100%', borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
              variant="contained"
              size="large"
              href="https://api.whatsapp.com/send/?phone=+56931107304&text&type=phone_number&app_absent=0">
              Ver Mas
            </Button>
          </CardActions>
        </Fragment>
      </Card>
    </Fade>
  );
};

export default AssistanceCard;
