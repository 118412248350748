import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Box, Typography } from '@mui/material';
import NordicToggleButtonGrid from './NordicToggleButtonGrid';
import { es } from '../../../language/es';

const NordicWeekScale2: FC = () => {
  const week = useSelector((state: RootState) => state.nordicForm.weekCheck);
  const scale: any[] = [];
  if (week.cuello === true) {
    scale.push({ name: 'cuello', tag: es.nordic.body.cuello });
  }
  if (week.hombro === true) {
    scale.push({ name: 'hombro', tag: es.nordic.body.hombro });
  }
  if (week.brazoCodo === true) {
    scale.push({ name: 'brazoCodo', tag: es.nordic.body.brazoCodo });
  }
  if (week.manoMuñeca === true) {
    scale.push({ name: 'manoMuñeca', tag: es.nordic.body.manoMuñeca });
  }
  if (week.espaldaAlta === true) {
    scale.push({ name: 'espaldaAlta', tag: es.nordic.body.espaldaAlta });
  }
  if (week.espaldaBaja === true) {
    scale.push({ name: 'espaldaBaja', tag: es.nordic.body.espaldaBaja });
  }
  if (week.caderaPierna === true) {
    scale.push({ name: 'caderaPierna', tag: es.nordic.body.caderaPierna });
  }
  if (week.rodilla === true) {
    scale.push({ name: 'rodilla', tag: es.nordic.body.rodilla });
  }
  if (week.tobilloPie === true) {
    scale.push({ name: 'tobilloPie', tag: es.nordic.body.tobilloPie });
  }

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '500px',
          maxWidth: '92vw',
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px',
        }}>
        <Typography variant="h5" sx={{ borderBottom: '1px solid grey' }}>
          {es.nordic.body.scaleQuestion}
        </Typography>
        <NordicToggleButtonGrid name="prueba" valueRange={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} columnVars={scale} />
      </Box>
    </Box>
  );
};

export default NordicWeekScale2;
