import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Box, Link } from '@mui/material';
import PlanCarousel from '../components/WorkoutPlan/PlanCarousel';
import { es } from '../language/es';
import OfflineToggleButton from '../components/HomeScreenComponents/OfflineToggleButton';

const Home: FC = () => {
  const { first_name } = useSelector((state: RootState) => state.user);

  return (
    <Box sx={{ paddingTop: '10vh' }}>
      <Box sx={{ textAlign: 'left', marginX: '5vw', marginY: '0', paddingBottom: '0' }}>
        {first_name !== 'Offline' ? (
          <h2 className="App-logo-text">{es.home.welcome(first_name)} 👨‍🚀</h2>
        ) : (
          <Box sx={{ borderRadius: '20px', padding: '15px', backgroundColor: '#DDDDDD88', marginY: 3 }}>
            <h3 className="App-logo-text">
              La app está en modo offline, por lo que se perderán algunos datos. Lamentamos los inconvenientes!
            </h3>
          </Box>
        )}
      </Box>
      <PlanCarousel />
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage: 'url(https://cdn.makana.cl/mkn-591.jpg)',
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100% auto`,
          backgroundPosition: 'center',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          minWidth: {
            xs: '90vw', // Dispositivos móviles
            sm: '400px', // Otros tamaños de pantalla
          },
          minHeight: '20vh',
          marginX: '5vw',
          marginTop: 3,
          marginBottom: 15,
          paddingBottom: '0vh',
        }}>
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: 'linear-gradient(to bottom right,#432680a0, #0068ffc0)',
            paddingBottom: '4vh',
            paddingTop: '0.5vh',
            width: '100%',
            height: '100%',
            minWidth: {
              xs: '90vw', // Dispositivos móviles
              sm: '400px', // Otros tamaños de pantalla
            },
            minHeight: '20vh',
            margin: '0vh',
          }}>
          <Box sx={{ marginX: '2vw', marginY: '5vw', paddingY: '0', fontSize: { sm: 24 }, lineHeight: { sm: 2.3 } }}>
            <h1 className="App-profile-text">{es.home.recommender.title}</h1>
          </Box>
          <Link
            href="/charlie"
            sx={{
              color: 'primary.main',
              backgroundColor: '#FFFFFFc0',
              width: '100%',
              borderRadius: '50px',
              fontWeight: 'bold',
              boxShadow: 'none',
              paddingY: '2vh',
              paddingX: '5vw',
              textDecoration: 'none',
              fontSize: '25px',
            }}>
            {es.home.recommender.button}
          </Link>
        </Box>
      </Box>
      <OfflineToggleButton />
    </Box>
  );
};

export default Home;
