import React, { FC, Fragment, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../redux/store';
import { Box, Fade, Link } from '@mui/material';
import PreviewCard from './PreviewCard';
import { es } from '../../language/es';
import * as planInteractors from '../../redux/interactors/PlanInteractors';

interface CtaProps {
  url: string;
}

const setCTA = (url: string) => {
  switch (url[1]) {
    case '1':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.singular}
          <Box
            sx={{
              fontSize: { xs: 70, sm: 120 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'center',
              lineHeight: 0.8,
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.neck}
          </Box>
        </Box>
      );
    case '2':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.plural}
          <Box
            sx={{
              fontSize: { xs: 70, sm: 120 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'right',
              lineHeight: 0.8,
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.shoulders}
          </Box>
        </Box>
      );
    case '3':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.singular}
          <Box
            sx={{
              fontSize: { xs: 40, sm: 105 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'left',
              lineHeight: { xs: 1.5, sm: 0.9 },
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.arms}
          </Box>
        </Box>
      );
    case '4':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.singular}
          <Box
            sx={{
              fontSize: { xs: 37, sm: 90 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'left',
              lineHeight: { xs: 1.5, sm: 1.1 },
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.hands}
          </Box>
        </Box>
      );
    case '5':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.singular}
          <Box
            sx={{
              fontSize: { xs: 37, sm: 90 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'left',
              lineHeight: { xs: 1.7, sm: 1.1 },
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.upperBack}
          </Box>
        </Box>
      );
    case '6':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.singular}
          <Box
            sx={{
              fontSize: { xs: 37, sm: 90 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'right',
              lineHeight: { xs: 1.7, sm: 1.1 },
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.lowerBack}
          </Box>
        </Box>
      );
    case '7':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.plural}
          <Box
            sx={{
              fontSize: { xs: 30, sm: 70 }, // aca
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'right',
              lineHeight: { xs: 1.7, sm: 1.35 },
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.legs}
          </Box>
        </Box>
      );
    case '8':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.plural}
          <Box
            sx={{
              fontSize: { xs: 70, sm: 120 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'center',
              lineHeight: 0.8,
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.knees}
          </Box>
        </Box>
      );
    case '9':
      return (
        <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
          {es.home.planCarousel.cardPhrase.plural}
          <Box
            sx={{
              fontSize: { xs: 37, sm: 90 },
              margin: 0,
              padding: 0,
              width: '100%',
              textAlign: 'right',
              lineHeight: { xs: 1.7, sm: 1.1 },
              fontWeight: 'bold',
            }}>
            {es.home.planCarousel.bodyParts.feet}
          </Box>
        </Box>
      );
    default:
      return 'No hay preferencia';
  }
};

const setImgUrl = (url: string) => {
  switch (url[1]) {
    case '1':
      return 'https://cdn.makana.cl/mkn-526.jpg';
    case '2':
      return 'https://cdn.makana.cl/mkn-026.jpg';
    case '3':
      return 'https://cdn.makana.cl/mkn-419.jpg';
    case '4':
      return 'https://cdn.makana.cl/mkn-327.jpg';
    case '5':
      return 'https://cdn.makana.cl/mkn-247.jpg';
    case '6':
      return 'https://cdn.makana.cl/mkn-057.jpg';
    case '7':
      return 'https://cdn.makana.cl/mkn-512.jpg';
    case '8':
      return 'https://cdn.makana.cl/mkn-075.jpg';
    case '9':
      return 'https://cdn.makana.cl/mkn-572.jpg';
    default:
      return '';
  }
};

const Cta2: FC<CtaProps> = (props: CtaProps) => {
  return (
    <Link
      sx={{
        color: 'primary.main',
        backgroundColor: 'white',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        fontSize: '25px',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      }}
      href={`/in/${props.url}`}>
      {es.home.planCarousel.cta2}
    </Link>
  );
};

const Cta3: FC<CtaProps> = (props: CtaProps) => {
  return (
    <Link
      sx={{
        color: '#11d43a',
        backgroundColor: 'white',
        minWidth: {
          xs: '60vw',
          sm: '60%',
        },
        borderRadius: '50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        paddingY: '2vh',
        paddingX: '5vw',
        marginX: '5vw',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        fontSize: '25px',
      }}
      href={`/in/${props.url}`}>
      {es.home.planCarousel.cta3}
    </Link>
  );
};

interface Props {
  getPlanInteractor: typeof planInteractors.getPlanInteractor;
}

const PlanCarousel: FC<Props> = (props: Props) => {
  const token = useSelector((state: RootState) => state.user.token);
  const count = useSelector((state: RootState) => state.plan.count) || 0;
  const priority1 = useSelector((state: RootState) => state.plan.priority1) || '';
  const priority2 = useSelector((state: RootState) => state.plan.priority2) || '';
  const plan = useSelector((state: RootState) => state.plan.plan);

  useEffect(() => {
    if (token) {
      props.getPlanInteractor([]);
    }
  }, []);
  return (
    <Fade in>
      <Box>
        {plan !== undefined && (
          <Box
            alignItems="stretch"
            sx={{
              textAlign: 'left',
              margin: '0',
              padding: '0',
            }}>
            <Box sx={{ paddingX: '5vw' }}>
              <h2 className="App-logo-text">{es.home.planCarousel.title}</h2>
            </Box>
            <Box
              sx={{
                marginY: '0',
                paddingX: '5vw',
                paddingY: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                gap: 3,
                py: 0,
                overflow: 'auto',
                width: '100vw',
                scrollSnapType: 'x mandatory',
                '& > *': {
                  scrollSnapAlign: 'center',
                },
                '::-webkit-scrollbar': { display: 'none' },
              }}>
              {plan !== undefined && count % 2 === 0 ? (
                <Fragment>
                  <PreviewCard
                    title={setCTA(priority1)}
                    callToAction={<Cta2 url={priority1} />}
                    urlParams="continue"
                    imgUrl={setImgUrl(priority1)}
                    gradient="linear-gradient(to bottom right,#0068ffc0, #7be7c660)"
                  />
                  <PreviewCard
                    title={setCTA(priority2)}
                    callToAction={<Cta3 url={priority2} />}
                    urlParams="continue"
                    imgUrl={setImgUrl(priority2)}
                    gradient="linear-gradient(to bottom right,#11d43ac0 0%, #92c41fa0 39%, #d1ff0040 100%)"
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <PreviewCard
                    title={setCTA(priority2)}
                    callToAction={<Cta3 url={priority2} />}
                    urlParams="continue"
                    imgUrl={setImgUrl(priority2)}
                    gradient="linear-gradient(to bottom right,#11d43ac0 0%, #92c41fa0 39%, #d1ff0040 100%)"
                  />
                  <PreviewCard
                    title={setCTA(priority1)}
                    callToAction={<Cta2 url={priority1} />}
                    urlParams="continue"
                    imgUrl={setImgUrl(priority1)}
                    gradient="linear-gradient(to bottom right,#0068ffc0, #7be7c660)"
                  />
                </Fragment>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...planInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(PlanCarousel);
