import React, { FC, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import businessService from '../../services/BusinessServices';
import { userActions } from '../../redux/slices/userSlice';
import { Box, TextField, Button } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';
import { formatValue, dgv } from './AddRUT';
import { useNavigate } from 'react-router-dom';

const CompanyUserLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.business) || '';

  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const changeRUT = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT ENTREGADO NO ES VALIDO';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
  };

  const login = async () => {
    try {
      const response = await businessService.loginUser({ rut: RUT }, token);
      console.log(response);
      if (response.user) {
        const { user } = response;
        user.token = response.token;
        user.redirect = response.redirect;
        console.log(user);
        dispatch(userActions.login(user));
      } else {
        navigate(`${response.redirect}${RUT}`);
      }
    } catch (error) {
      navigate('/register?rut=' + RUT);
    }
  };

  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo_vrt} height={'100px'} />
      </Box>
      <Box
        sx={{
          width: '100%',
          marginY: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
        }}>
        <h1 className="App-logo-text">INGRESA ACÁ</h1>
      </Box>
      <Box sx={{ marginY: '15px' }}>
        <TextField
          sx={{ width: '100%', marginY: '2%' }}
          label="RUT"
          variant="outlined"
          value={RUT}
          onChange={changeRUT}
          error={RUTError !== ''}
          helperText={RUTError}
        />
      </Box>
      <Button
        onClick={login}
        size="large"
        disabled={RUTError !== '' || RUT.length < 1}
        sx={{ width: '100%', marginY: '5%', fontWeight: 'bold', borderRadius: '50px', fontSize: '20px' }}
        variant="contained">
        INICIAR SESION
      </Button>
    </Box>
  );
};

export default CompanyUserLogin;
