import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as YourSvg } from '../../../images/logo.svg';
import { es } from '../../../language/es';
import PlanAnimation from './PlanAnimation';
import * as planInteractors from '../../../redux/interactors/PlanInteractors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// ANIMADO EN https://svgartista.net

interface Props {
  getPlanInteractor: typeof planInteractors.getPlanInteractor;
}

const LogoAnimation: FC<Props> = (props: Props) => {
  const [isActive, setIsActive] = useState(true);
  const [showPlan, setShowPlan] = useState(false);

  useEffect(() => {
    props.getPlanInteractor([]);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive((prevState) => !prevState); // toggle the state
    }, 2000); // every 2 seconds

    const timeout = setTimeout(() => {
      clearInterval(interval); // stop the interval
      setIsActive(true); // set isActive to true
      setShowPlan(true); // set showPlan to true
    }, 5000); // after 9 seconds

    // Cleanup function to clear the interval and timeout when the component unmounts
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        overflow: 'visible',
      }}>
      <Box
        sx={{
          display: 'block',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
        }}>
        <Box
          sx={{
            marginTop: '5vh',
            marginX: showPlan ? '43vw' : '30vw',
            width: showPlan ? '14vw' : '40vw',
            maxWidth: '300px',
            maxHeight: '50vh',
            transition: 'all 0.5s linear',
          }}>
          <YourSvg className={isActive ? 'active' : ''} />
        </Box>
        <Box sx={{ marginX: '10vw', width: '80vw' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {showPlan ? <PlanAnimation /> : es.nordic.waiting}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...planInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(LogoAnimation);
