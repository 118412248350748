// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck projectActions properties generated at runtime
import { buildInteractor, buildInteractorDirectActionNoParams, buildInteractorDirectAction } from './base';
import { planActions } from '../slices/planSlice';
import userService from '../../services/UserServices';

export const logoutPlanInteractor = buildInteractorDirectActionNoParams(planActions.logout);
export const getPlanInteractor = buildInteractor(
  planActions.loadingGetPlan,
  planActions.successGetPlan,
  planActions.errorGetPlan,
  userService.getUserPlan,
);
export const updatePlanInteractor = buildInteractorDirectAction(planActions.updatePlan);
