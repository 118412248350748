import { Plan } from '../types/planTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action, baseRequestStatusReducers } from './base';

const initialState: Plan = {
  // pass
  getPlanStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successGetPlan = (state: Plan, action: Action) => {
  return {
    ...state,
    id: action.payload.plan.id,
    plan: action.payload.plan.plan,
    priority1: action.payload.plan.priority_1,
    priority2: action.payload.plan.priority_2,
    createdAt: action.payload.plan.created_at,
    x: action.payload.plan.x,
    y: action.payload.plan.y,
    z: action.payload.plan.z,
    count: action.payload.plan.count,
    getPlanStatus: { loading: false, success: true, error: false },
  };
};

const PlanSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    ...baseRequestStatusReducers('getPlan', initialState, null, successGetPlan),
    logout: () => {
      console.log('logout PLAN');
      return {
        ...initialState,
      };
    },
    update(state: Plan, action: Action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const planActions = PlanSlice.actions;
export const planReducer = PlanSlice.reducer;
