import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { UserAuthFields } from './../redux/types/userTypes';
import { BusinessUserAuthFields } from './../redux/types/businessTypes';

interface User {
  id: number;
  first_name: string;
  last_name: string;
}

const loginAdmin = async (authFields: UserAuthFields): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/business_login`,
    data: {
      user: {
        email: authFields.email,
        password: authFields.password,
      },
    },
  });
  return response?.data;
};

const loginUser = async (authFields: BusinessUserAuthFields, token?: string): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: token ? `Bearer ${token}` : '' },
    method: 'post',
    url: `/users/totem_login`,
    data: {
      user: {
        rut: authFields.rut,
      },
    },
  });
  return response?.data;
};

const getUsers = async (token: string): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: token ? `Bearer ${token}` : '' },
    method: 'get',
    url: `/update_list`,
  });
  return response?.data;
};

const businessService = {
  loginAdmin,
  loginUser,
  getUsers,
};

export default businessService;
