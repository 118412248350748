import React, { FC } from 'react';
import BodyMap from '../components/BodyScreenComponents/BodyMap';
import PopUpMenu from '../components/BodyScreenComponents/PopUpMenu';
import { Box } from '@mui/material';
import stock_4 from './../images/stock_4.png';

const styles = {
  boxContainer: {
    backgroundImage: `url(${stock_4})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    paddingTop: '10vh',
    paddingBottom: '0',
    overflow: 'scroll',
    minHeight: '100vh',
    height: '100%',
  },
  bodyContainer: {
    backgroundColor: 'rgba(255, 255, 255, .17)',
    height: '90vh',
  },
};

const BodyMapScreen: FC = () => {
  return (
    <Box style={styles.boxContainer}>
      <Box style={styles.bodyContainer}>
        <BodyMap />
      </Box>
      <PopUpMenu />
    </Box>
  );
};

export default BodyMapScreen;
