import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { AppBar, Container, Toolbar, Box } from '@mui/material';
import { Chip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import makana_small from './../images/logo_small.png';
import Timer from '../components/OtherComponents/Timer';
import { useDispatch } from 'react-redux';
import { userActions } from '../redux/slices/userSlice';

const MakanaBar: FC = () => {
  const { token } = useSelector((state: RootState) => state.user);
  const id = useSelector((state: RootState) => state.protocol.protocol?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(userActions.setMode({ mode: '' }));
    if (id !== undefined) {
      dispatch(userActions.setMode({ mode: '' }));
    }
  }, []);

  const exit = () => {
    if (window.location.pathname.includes('routine') === false) {
      navigate(`/`);
      dispatch(userActions.logout());
    } else {
      navigate(`/out/${window.location.pathname.substring(9)}`);
      dispatch(userActions.setMode({ mode: '' }));
    }
  };

  return (
    <AppBar sx={{ background: 'white' }}>
      <Container>
        <Toolbar sx={{ postition: 'relative', top: '0' }}>
          {token !== undefined ? (
            <img src={makana_small} height={'30px'} />
          ) : (
            <img src={makana_small} height={'30px'} />
          )}
          <Box sx={{ marginLeft: '10px', paddingBottom: '0', marginTop: '8px' }}>
            {token !== undefined && <Timer />}
          </Box>
          <Box sx={{ position: 'fixed', right: '10vw', top: '16px' }}>
            {token !== undefined && (
              <Box>
                <Chip onClick={exit} color="error" icon={<ExitToAppIcon />} label="Salir" sx={{ fontSize: '20px' }} />
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MakanaBar;
