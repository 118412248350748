import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { bodyActions } from '../../redux/slices/bodySlice';
import './BodyMap.css';

const BodyBackMap: FC = () => {
  const dispatch = useDispatch();

  const onClickSvg = ({ target }: any) => {
    const id = target.id || target.parentElement.id || target.parentElement.parentElement.id;
    dispatch(bodyActions.select(id));
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 310.000000 760.000000">
      <g transform="translate(0.000000,760.000000) scale(0.100000,-0.100000)">
        <g onClick={onClickSvg} className="sector" id="head">
          <path d="M1475 7511 c-68 -11 -114 -32 -160 -72 -61 -54 -78 -104 -83 -249 -7 -211 32 -375 96 -403 28 -13 97 -55 144 -88 43 -30 82 -34 115 -12 15 11 64 39 108 63 95 51 120 72 148 125 20 37 22 54 22 235 0 230 -9 265 -81 329 -73 64 -189 91 -309 72z" />
          <path d="M1202 6968 c-7 -7 -12 -21 -12 -32 0 -22 38 -91 45 -84 3 2 -1 32 -8 66 -10 49 -15 60 -25 50z" />
          <path d="M1884 6943 c-3 -15 -10 -43 -16 -61 -5 -18 -8 -36 -5 -39 8 -7 47 73 47 96 0 32 -19 35 -26 4z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="trapezius">
          <path d="M1318 6719 c-13 -7 -18 -23 -18 -53 0 -73 -28 -147 -72 -188 -22 -22 -49 -38 -63 -38 -65 0 -215 -68 -215 -97 0 -19 32 -16 91 7 64 26 84 12 29 -19 -22 -12 -40 -26 -40 -31 0 -14 36 -31 88 -40 26 -5 107 -25 181 -44 74 -20 138 -36 142 -36 4 0 9 39 10 86 3 72 7 88 24 99 46 33 47 34 43 130 -3 82 -6 95 -31 128 -28 37 -122 107 -142 107 -5 0 -18 -5 -27 -11z" />
          <path d="M1677 6694 c-32 -17 -58 -40 -78 -71 -26 -41 -29 -56 -29 -122 0 -96 7 -120 35 -127 32 -8 45 -44 45 -124 0 -39 3 -70 8 -70 10 0 320 78 366 93 22 6 41 17 43 24 2 6 -14 21 -37 34 -55 31 -35 45 29 19 59 -23 91 -26 91 -7 0 34 -152 97 -235 97 -39 0 -47 4 -78 43 -49 61 -57 79 -64 155 -8 91 -18 97 -96 56z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftShoulder">
          <path d="M845 6269 c-42 -38 -126 -142 -149 -186 -15 -30 -22 -73 -12 -73 6 0 185 185 222 229 27 32 27 34 9 47 -26 19 -29 18 -70 -17z" />
          <path d="M861 6159 c-56 -62 -114 -132 -128 -156 -32 -54 -53 -139 -53 -212 l0 -55 32 45 c53 74 90 109 153 142 32 18 74 46 91 64 42 41 116 177 112 204 -2 14 -21 32 -54 50 l-50 29 -103 -111z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightShoulder">
          <path d="M2185 6286 c-18 -13 -18 -14 4 -42 27 -35 220 -234 227 -234 10 0 3 43 -12 73 -35 67 -167 217 -192 217 -5 0 -17 -6 -27 -14z" />
          <path d="M2082 6240 c-37 -22 -52 -37 -52 -51 0 -30 76 -164 114 -202 17 -18 59 -46 91 -64 63 -33 100 -68 153 -142 l32 -45 0 55 c0 73 -21 158 -52 210 -22 37 -128 161 -211 246 l-22 23 -53 -30z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="upperBack">
          <path d="M1195 6139 c-4 -5 -2 -24 4 -42 12 -38 27 -128 41 -257 11 -101 57 -279 140 -549 57 -184 55 -181 77 -181 12 0 14 7 8 33 -4 17 -9 244 -12 504 l-5 471 -22 6 c-13 3 -56 8 -97 11 -41 3 -86 8 -101 10 -15 2 -30 0 -33 -6z" />
          <path d="M1800 6139 c-47 -4 -99 -11 -117 -14 l-32 -6 -5 -457 c-2 -251 -7 -478 -11 -504 -8 -54 5 -63 31 -23 28 43 179 571 188 660 13 120 28 221 42 278 14 54 12 79 -4 76 -4 -1 -45 -5 -92 -10z" />
          <path d="M1086 5995 c-43 -72 -82 -111 -136 -134 -56 -24 -63 -43 -25 -73 27 -21 64 -87 84 -149 9 -29 8 -54 -4 -124 l-15 -88 29 -76 c32 -84 51 -209 51 -339 0 -109 16 -147 72 -172 24 -11 48 -20 55 -20 6 0 26 24 43 54 17 30 49 76 71 103 22 26 42 58 45 70 3 12 -8 77 -24 145 -17 68 -39 159 -50 203 -38 154 -164 640 -167 643 -1 2 -15 -18 -29 -43z" />
          <path d="M1910 5758 c-107 -417 -154 -612 -166 -679 -5 -30 0 -42 30 -80 19 -24 54 -75 77 -112 40 -65 43 -68 73 -61 80 17 106 63 106 186 0 130 19 255 51 339 l29 76 -15 87 c-8 47 -12 99 -9 114 12 48 62 139 89 160 14 11 25 26 25 34 0 8 -28 29 -62 46 -55 27 -69 40 -107 102 -24 38 -45 70 -46 70 -1 0 -35 -127 -75 -282z" />
          <path d="M1523 6309 c-12 -4 -25 -20 -29 -34 -7 -29 2 -819 11 -950 3 -44 7 -270 8 -502 2 -371 4 -425 18 -439 14 -14 18 -14 31 0 13 13 17 71 22 363 4 191 11 553 17 803 5 250 9 519 8 596 l-1 142 -26 13 c-37 18 -34 18 -59 8z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftArm">
          <path d="M807 5722 c-20 -21 -59 -51 -86 -67 -29 -18 -61 -48 -78 -75 -28 -44 -28 -47 -28 -200 0 -139 4 -196 21 -310 10 -68 12 -70 63 -70 27 0 63 -8 86 -20 22 -11 40 -20 42 -20 8 0 44 84 52 122 5 23 24 154 41 291 l32 247 -31 48 c-17 27 -41 58 -54 70 l-23 22 -37 -38z" />
          <path d="M681 4914 c-6 -15 -14 -46 -18 -70 -8 -62 13 -58 77 14 l51 57 -28 12 c-45 20 -69 16 -82 -13z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightArm">
          <path d="M2229 5732 c-14 -15 -38 -44 -54 -66 l-28 -38 37 -281 c29 -223 41 -291 61 -334 13 -29 25 -53 27 -53 2 0 21 9 43 20 23 12 59 20 86 20 32 0 49 5 52 14 17 44 38 281 35 394 -3 120 -5 130 -32 173 -16 25 -47 56 -69 68 -23 13 -62 43 -87 67 l-47 44 -24 -28z" />
          <path d="M2337 4927 l-28 -12 51 -57 c64 -72 85 -76 77 -14 -13 92 -36 111 -100 83z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="lowerBack">
          <path d="M1357 4853 c-48 -61 -90 -125 -93 -141 -5 -24 3 -41 46 -98 109 -147 132 -175 143 -171 11 3 11 99 -2 377 l-6 145 -88 -112z" />
          <path d="M1647 4787 c-4 -99 -7 -219 -7 -266 l0 -86 48 50 c26 28 71 84 100 125 44 60 53 79 48 102 -3 16 -46 80 -94 142 l-89 114 -6 -181z" />
          <path d="M1066 4745 c-3 -31 -15 -79 -26 -108 -27 -70 -26 -72 15 -41 37 28 121 50 139 36 6 -5 19 -12 29 -15 20 -7 20 -7 -25 59 -15 23 -28 47 -28 53 0 11 -76 71 -90 71 -4 0 -10 -25 -14 -55z" />
          <path d="M1984 4782 c-43 -23 -124 -129 -124 -162 0 -6 25 -7 60 -3 57 6 63 4 105 -26 25 -18 45 -29 45 -24 0 4 -9 46 -20 93 -11 47 -20 97 -20 113 0 31 -4 32 -46 9z" />
          <path d="M1085 4531 c-64 -37 -70 -44 -90 -113 -9 -31 -34 -83 -56 -115 -21 -32 -39 -65 -39 -74 0 -9 -13 -69 -30 -132 -22 -87 -30 -138 -30 -204 l0 -88 77 -78 c87 -88 123 -104 238 -103 64 1 80 6 188 58 65 31 131 66 146 78 37 26 85 26 122 0 15 -12 81 -46 146 -78 103 -50 126 -57 183 -58 115 -3 159 16 245 104 l75 77 0 88 c0 66 -8 117 -30 204 -16 63 -30 123 -30 132 0 10 -18 42 -39 72 -22 30 -44 74 -51 98 -25 95 -98 161 -176 161 -76 -1 -137 -38 -253 -152 -83 -83 -114 -108 -134 -108 -21 0 -52 26 -139 116 -62 64 -123 120 -135 124 -11 5 -47 12 -80 15 -52 5 -63 3 -108 -24z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftHand">
          <path d="M560 4883 c-59 -142 -109 -279 -129 -359 -23 -89 -66 -336 -76 -435 -6 -61 -5 -67 19 -96 34 -40 90 -61 113 -41 26 21 257 497 287 590 26 81 70 318 60 327 -2 2 -10 -2 -17 -11 -107 -136 -109 -138 -143 -138 -37 0 -34 -7 -54 145 -6 44 -14 83 -19 88 -5 5 -23 -26 -41 -70z" />
          <path d="M222 3975 c-116 -55 -222 -136 -222 -168 0 -22 13 -21 83 3 97 34 97 33 21 -121 -95 -194 -108 -240 -57 -204 11 8 41 46 68 84 26 39 54 71 61 71 19 0 18 -8 -16 -100 -32 -87 -36 -115 -20 -125 18 -11 40 16 86 106 46 92 59 108 76 91 8 -8 3 -37 -16 -97 -15 -47 -24 -90 -21 -95 14 -22 40 9 80 96 24 52 49 94 54 94 14 0 14 -42 0 -114 -21 -103 16 -83 50 27 11 37 26 116 32 175 14 124 5 185 -26 190 -64 10 -103 34 -126 77 -13 25 -26 45 -29 45 -3 0 -38 -16 -78 -35z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightHand">
          <path d="M2496 4952 c-5 -8 -32 -182 -36 -229 0 -2 -15 -3 -32 -3 -29 1 -42 11 -96 76 -35 42 -65 74 -67 72 -8 -9 37 -253 61 -326 30 -93 262 -569 287 -590 23 -20 79 1 113 41 31 36 30 49 -12 302 -32 197 -61 300 -128 472 -65 169 -81 200 -90 185z" />
          <path d="M2771 3965 c-23 -43 -62 -67 -126 -77 -31 -5 -40 -66 -26 -190 6 -59 21 -138 32 -175 34 -110 71 -130 50 -27 -14 72 -14 114 0 114 5 0 30 -42 54 -94 40 -87 66 -118 80 -96 3 5 -6 48 -21 95 -19 60 -24 89 -16 97 17 17 30 1 77 -92 76 -152 122 -142 67 15 -37 103 -37 105 -18 105 7 0 35 -32 61 -71 27 -38 57 -76 68 -84 51 -36 38 10 -57 204 -76 154 -76 155 21 121 71 -25 83 -25 83 -1 0 28 -101 105 -205 156 -50 25 -93 45 -95 45 -3 0 -16 -20 -29 -45z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftLeg">
          <path d="M853 3649 c3 -13 9 -68 13 -121 4 -54 9 -98 13 -97 9 1 101 117 101 127 0 8 -91 90 -119 107 -11 6 -13 3 -8 -16z" />
          <path d="M1395 3646 c-33 -12 -87 -36 -119 -54 -49 -26 -71 -32 -123 -32 l-63 0 6 -27 c3 -16 47 -291 99 -612 52 -321 96 -585 98 -587 3 -3 16 0 29 7 23 10 26 20 41 142 10 73 24 200 32 282 8 83 19 182 25 220 5 39 12 140 14 227 3 86 10 198 16 250 6 51 10 118 8 149 l-3 57 -60 -22z" />
          <path d="M981 3423 c-36 -56 -60 -139 -72 -253 -20 -191 -4 -287 120 -694 43 -143 60 -165 124 -166 29 0 37 4 37 18 0 23 -177 1123 -182 1127 -2 2 -14 -12 -27 -32z" />
          <path d="M1031 1888 c-12 -33 -29 -164 -41 -312 -15 -192 6 -379 66 -596 15 -52 30 -99 35 -104 5 -5 32 9 64 33 l56 43 -6 246 c-8 359 -24 612 -41 644 -33 63 -116 92 -133 46z" />
          <path d="M1265 1783 c4 -21 9 -216 12 -434 l4 -395 30 -18 c35 -20 35 -20 43 79 3 39 15 106 26 150 26 103 37 307 21 399 -17 96 -48 172 -88 217 -45 49 -57 50 -48 2z" />
          <path d="M1215 891 c-11 -5 -39 -26 -63 -47 l-43 -39 35 -88 c71 -176 100 -212 138 -174 7 7 21 16 31 20 13 5 17 18 17 55 0 27 3 83 6 125 l7 76 -40 41 c-42 41 -53 45 -88 31z" />
          <path d="M1000 2151 c0 -125 4 -191 10 -191 6 0 13 6 16 14 3 8 19 16 36 20 46 9 91 -17 112 -65 17 -37 40 -58 52 -47 11 12 65 284 60 304 -8 29 -54 84 -80 93 -11 5 -37 3 -58 -2 -33 -8 -42 -7 -61 9 -12 11 -37 27 -54 37 l-33 18 0 -190z" />
          <path d="M1360 2199 c-33 -13 -40 -26 -40 -75 0 -23 -9 -86 -20 -139 -23 -113 -24 -125 -8 -125 6 0 20 -7 30 -17 10 -9 20 -14 22 -12 1 2 18 40 36 84 30 70 34 94 38 188 5 117 3 120 -58 96z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightLeg">
          <path d="M2182 3618 c-34 -27 -62 -54 -62 -60 0 -10 92 -126 101 -127 5 -1 29 230 25 236 -1 1 -30 -21 -64 -49z" />
          <path d="M1640 3592 c0 -42 5 -106 10 -142 5 -36 12 -141 15 -235 4 -93 10 -199 16 -235 5 -36 16 -132 24 -215 8 -82 22 -209 32 -282 15 -122 18 -132 41 -142 13 -7 27 -10 29 -7 2 2 46 267 98 587 52 321 96 596 99 612 l6 27 -69 0 c-38 0 -71 3 -73 8 -4 9 -123 64 -185 86 l-43 15 0 -77z" />
          <path d="M2086 3433 c-11 -55 -176 -1089 -176 -1106 0 -13 9 -17 38 -17 63 1 80 23 123 166 124 407 140 503 120 694 -12 116 -37 200 -74 255 l-25 37 -6 -29z" />
          <path d="M1995 1900 c-11 -4 -31 -20 -45 -34 -32 -36 -36 -79 -50 -534 -9 -297 -9 -377 1 -389 24 -28 101 -75 109 -67 13 14 68 228 86 339 21 130 21 333 0 510 -23 191 -21 185 -54 184 -15 0 -36 -4 -47 -9z" />
          <path d="M1793 1788 c-40 -42 -71 -111 -90 -202 -20 -95 -12 -309 17 -421 11 -44 23 -111 26 -150 8 -99 8 -99 43 -79 l30 18 5 390 c2 215 7 410 11 434 9 51 -1 53 -42 10z" />
          <path d="M1799 861 l-42 -41 7 -68 c3 -37 6 -93 6 -125 0 -46 4 -59 18 -64 9 -4 23 -13 30 -20 32 -31 57 -8 102 90 37 82 73 177 68 181 -1 1 -23 19 -48 38 -71 57 -92 58 -141 9z" />
          <path d="M2062 2320 c-19 -10 -42 -26 -52 -35 -18 -17 -33 -16 -92 1 -21 6 -82 -46 -98 -85 -10 -26 -8 -49 15 -158 15 -70 31 -136 36 -145 13 -24 35 -13 50 24 20 48 48 68 95 68 25 0 47 -6 54 -15 7 -8 16 -12 21 -9 8 4 13 376 6 373 -1 0 -17 -9 -35 -19z" />
          <path d="M1682 2103 c4 -94 8 -118 38 -188 18 -44 35 -82 36 -84 2 -2 12 3 22 12 10 10 24 17 30 17 16 0 15 12 -8 125 -11 53 -20 116 -20 139 0 50 -7 62 -43 75 -59 23 -60 21 -55 -96z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="leftFoot">
          <path d="M1250 432 c-57 -24 -68 -33 -91 -75 -14 -26 -36 -59 -50 -74 -65 -72 -81 -104 -83 -158 0 -33 4 -59 12 -67 10 -10 49 -12 155 -11 162 2 175 8 160 73 -4 19 -8 91 -8 160 -1 118 -9 181 -24 179 -3 0 -35 -13 -71 -27z" />
        </g>
        <g onClick={onClickSvg} className="sector" id="rightFoot">
          <path d="M1755 433 c-16 -24 -16 -35 -5 -127 9 -74 9 -108 1 -129 -15 -38 -13 -88 3 -110 13 -16 29 -18 163 -16 162 2 193 11 193 56 0 36 -27 81 -59 97 -37 20 -98 105 -106 148 -4 19 -15 39 -25 46 -15 9 -139 62 -146 62 -1 0 -10 -12 -19 -27z" />
        </g>
      </g>
    </svg>
  );
};

export default BodyBackMap;
