import { RegisterState } from '../types/registerTypes';
import { createSlice } from '@reduxjs/toolkit';
import { Action, baseRequestStatusReducers } from './base';

const initialState: RegisterState = {
  registerIn: {},
  registerOut: {},
  postRegisterInStatus: {
    loading: false,
    success: false,
    error: false,
  },
  postRegisterOutStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successPostRegisterIn = (state: RegisterState) => {
  return {
    ...state,
    registerIn: {},
    postRegisterInStatus: { loading: false, success: true, error: false },
  };
};

const successPostRegisterOut = (state: RegisterState) => {
  return {
    ...state,
    registerOut: {},
    postRegisterOutStatus: { loading: false, success: true, error: false },
  };
};

const RegisterSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    ...baseRequestStatusReducers('postRegisterIn', initialState, null, successPostRegisterIn),
    ...baseRequestStatusReducers('postRegisterOut', initialState, null, successPostRegisterOut),
    updateRegisterIn: (state: RegisterState, action: Action) => {
      return {
        ...state,
        registerIn: {
          ...state.registerIn,
          [action.payload.name]: action.payload.value,
        },
      };
    },
    updateRegisterOut: (state: RegisterState, action: Action) => {
      return {
        ...state,
        registerOut: {
          ...state.registerOut,
          [action.payload.name]: action.payload.value,
        },
      };
    },
    resetRegisterIn: (state: RegisterState) => {
      return {
        ...state,
        registerIn: {},
        postRegisterInStatus: {
          loading: false,
          success: false,
          error: false,
        },
      };
    },
    resetRegisterOut: (state: RegisterState) => {
      return {
        ...state,
        registerOut: {},
        postRegisterOutStatus: {
          loading: false,
          success: false,
          error: false,
        },
      };
    },
  },
});

export const registerActions = RegisterSlice.actions;
export const registerReducer = RegisterSlice.reducer;
