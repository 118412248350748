export const es: any = {
  home: {
    welcome: (name: string) => `Hola, ${name}!`,
    points: (points: number, position: number) =>
      `🏆 Tienes ${points} puntos en el desafío Makana y estás en el puesto #${position} del ranking!`,
    planCarousel: {
      title: 'Nosotros te recomendamos... 🔥',
      cta0: 'Empecemos!',
      cta1: 'Empecemos!',
      cta2: 'Empecemos!',
      cta3: 'Hagámoslo!',
      cardPhrase: {
        singular: 'ALIVIA TU',
        plural: 'ALIVIA TUS',
        default: 'Continúa con TU plan',
        countPhrase: (count: number, sessions: number) => `Llevas ${count} / ${sessions} sesiones\nesta semana!`,
      },
      bodyParts: {
        neck: 'CUELLO',
        shoulders: 'HOMBROS',
        arms: 'BRAZO / CODO',
        hands: 'MUÑECA / MANO',
        upperBack: 'ESPALDA ALTA',
        lowerBack: 'ESPALDA BAJA',
        legs: 'CADERAS / PIERNAS',
        knees: 'RODILLAS',
        feet: 'TOBILLOS / PIES',
      },
    },
    recommender: {
      title: 'Quieres probar algo DIFERENTE?',
      button: 'ENCUÉNTRALO ACÁ',
    },
  },
  body: {
    menuTitle: 'Rutinas',
    bodyParts: {
      head: 'Cuello',
      trapezius: 'Cuello',
      chest: '',
      stomach: '',
      leftShoulder: 'Hombro',
      leftArm: 'Brazo / Codo',
      leftHand: 'Muñeca / Mano',
      leftLeg: 'Pierna / Rodilla',
      leftFoot: 'Pie / Planta del Pie',
      rightShoulder: 'Hombro',
      rightArm: 'Brazo / Codo',
      rightHand: 'Muñeca / Mano',
      rightLeg: 'Pierna / Rodilla',
      rightFoot: 'Pie / Planta del Pie',
      lowerBack: 'Espalda Baja / Glúteo',
      upperBack: 'Espalda Alta',
    },
  },
  others: {
    cards: {
      cta: 'Ver más',
    },
  },
  register: {
    q1: '¿Qué tan **CANSADO** te sientes?',
    q1ScaleLow: 'Cansado',
    q1ScaleHigh: 'Descansado',
    q2: (bodyPart: string) => `¿Cuánta **MOLESTIA FISICA** tienes${bodyPart !== '' ? ` en ${bodyPart}` : ''}?`,
    q2ScaleLow: 'Mucha Molestia',
    q2ScaleHigh: 'Sin Molestia',
    q3In: '¿Cómo te sientes **AHORA** (antes de la sesión)?',
    q3Out: '¿Cómo te sientes **AHORA** (después de la sesión)?',
    q3ScaleLow: 'Muy Mal',
    q3ScaleHigh: 'Muy Bien',
    ctaIn: 'EMPECEMOS',
    ctaOut: 'TERMINAR SESIÓN',
    bodyPart: (url: string) => {
      switch (url[1]) {
        case '1':
          return 'el cuello';
        case '2':
          return 'los hombros';
        case '3':
          return 'el brazo/codo';
        case '4':
          return 'la muñeca/mano';
        case '5':
          return 'la espalda alta';
        case '6':
          return 'la espalda baja';
        case '7':
          return 'las caderas/piernas';
        case '8':
          return 'las rodillas';
        case '9':
          return 'los tobillos/pies';
        default:
          return '';
      }
    },
  },
  nordic: {
    back: 'Atrás',
    next: 'Siguiente',
    intro: {
      title: 'Hola',
      body: 'Contesta el siguiente cuestionario a conciencia para poder recomendarte rutinas a TU medida y tener una **mejor experiencia** en las estaciones de **Makana**',
    },
    body: {
      firstCheck: '12 meses',
      weekCheck: '7 días',
      question: 'Marca los lugares donde hayas sentido molestias o dolores en los últimos',
      cuello: 'Cuello',
      hombro: 'Hombros',
      brazoCodo: 'Brazo/Codo',
      manoMuñeca: 'Mano/Muñeca',
      espaldaAlta: 'Espalda Alta',
      espaldaBaja: 'Espalda Baja',
      caderaPierna: 'Cadera/Pierna',
      rodilla: 'Rodilla',
      tobilloPie: 'Tobillo/Pie',
      scaleQuestion: '¿Cuánto fue la intensidad del dolor en los últimos 7 días?',
      scaleLow: '< BAJO dolor',
      scaleHigh: 'ALTO dolor >',
      intensity: {
        low: 'Intensidad BAJA',
        mid: 'Intensidad MEDIA',
        high: 'Intensidad ALTA',
        veryHigh: 'Intensidad MUY ALTA',
      },
    },
    partDetail: {
      zero: '0 dias',
      week: '1 a 7 dias',
      month: '8 a 30 dias',
      more: 'Más de 30 dias',
      all: 'Todos los dias',
      yes: 'Si',
      no: 'No',
      q1: (tag: string) =>
        `¿Cúal es el tiempo total que has tenido problemas en **${tag}** durante los **últimos 12 meses**?`,
      q2: '¿Tu molestia es producto de un **golpe, caída o torcedura** reciente?',
      q3: '¿Presentas **hinchazón o inflamación** en la zona?',
      lowerBackDetails: 'la parte baja de la espalda',
      neckDetails: 'el cuello',
      shouldersDetails: 'el hombro',
      elbowsDetails: 'el codo',
      handsDetails: 'la mano/muñeca',
      upperBackDetails: 'la parte alta de la espalda',
      legsDetails: 'la cadera/pierna',
      kneesDetails: 'la rodilla',
      anklesDetails: 'el tobillo/pie',
    },
    submit: 'ENVIAR',
    waiting: 'Estamos preparando TU plan...',
    plan: {
      description: (plan: string, priority1: string, priority2: string, x: number, y: number, z: number) => {
        return `${es.nordic.plan.setPriorities(
          es.nordic.plan.priorityBadge(priority1),
          es.nordic.plan.priorityBadge(priority2),
          z,
        )} ${es.nordic.plan.setPain(x, y, z)}${es.nordic.plan.setPlan(plan)}${es.nordic.plan.setRandomEnd()}`;
      },
      priorityBadge: (priority: string) => {
        switch (priority[1]) {
          case '1':
            return 'CUELLO';
          case '2':
            return 'HOMBROS';
          case '3':
            return 'BRAZO / CODO';
          case '4':
            return 'MUÑECA / MANO';
          case '5':
            return 'ESPALDA ALTA';
          case '6':
            return 'ESPALDA BAJA';
          case '7':
            return 'CADERA / PIERNAS';
          case '8':
            return 'RODILLAS';
          case '9':
            return 'TOBILLOS / PIES';
          default:
            return 'No hay preferencia';
        }
      },
      setPain: (x: number, y: number, z: number) => {
        let pain = '';
        if (y > 0 && y <= 2) {
          pain += 'Además, tienes una molestia aguda';
        } else if (y > 2) {
          pain += 'Además, tienes una molestia prolongada';
        }
        if (x >= 9) {
          pain += ' de **INTENSIDAD ALTA**';
        } else if (x >= 7) {
          pain += ' de **INTENSIDAD MEDIA**';
        } else if (x >= 4) {
          pain += ' de **INTENSIDAD BAJA**';
        } else if (y > 0) {
          pain += ' no significativa';
        }
        if (z > 4 && y > 0) {
          pain += ', considerando además un malestar generalizado';
        }
        return pain;
      },
      setPlan: (plan: string) => {
        let badge =
          ', por lo que  te hemos puesto en el plan **PREVENTIVO**, recomendando el uso de makana 2 veces por semana para **PREVENIR** molestias en el futuro.';
        if (plan === 'c3') {
          badge =
            ', por lo que  te hemos puesto en el plan **CORRECTIVO ALTO**, recomendando el uso de makana **5 VECES** por semana hasta **ELIMINAR** estas molestias.';
        } else if (plan === 'c2') {
          badge =
            ', por lo que  te hemos puesto en el plan **CORRECTIVO MEDIO**, recomendando el uso de makana **5 VECES**  por semana hasta **ELIMINAR** estas molestias.';
        } else if (plan === 'c1') {
          badge =
            ', por lo que  te hemos puesto en el plan **CORRECTIVO BAJO**, recomendando el uso de makana **3 VECES**  por semana para **evitar** que estas molestias se vuelvan significativas.';
        }
        return badge;
      },
      setPriorities: (priority1: string, priority2: string, z: number) => {
        if (z === 0) {
          // No prioridades reales, ambas asignadas
          return `Al no presentar molestias significativas, hemos asignado **${priority1}** y **${priority2}** como tus prioridades, pensando en las molestias más comunes en el trabajo.`;
        } else if (z === 1) {
          // Solo una prioridad real, otra asignada
          return `Has declarado **${priority1}** como tu prioridad principal. También agregamos **${priority2}** como tu segunda prioridad, pensando en las molestias más comunes en el trabajo.`;
        } else {
          // Ambas prioridades reales
          return `De acuerdo a las molestias declaradas, tus prioridades a trabajar son **${priority1}** y **${priority2}**.`;
        }
      },
      setRandomEnd: () => {
        const messages = [
          ' Recuerda que **tu bienestar es esencial** para tu desempeño.',
          ' **¡Estamos aquí para ayudarte!**',
          ' Recuerda que **tu cuerpo es tu principal herramienta de trabajo**!',
        ];
        const randomIndex = Math.floor(Math.random() * messages.length);
        return messages[randomIndex];
      },
    },
  },
};

// x=dolor más alto, y=aparicion del dolor, z=cantidad de zonas con dolor.
