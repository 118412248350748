import React, { FC, useState, useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import recomendador, { lx, ax, ix, hx, tx } from './tree';
import recomendador_safe from './tree_safe';
import { Box, Button, Stack, Chip } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import MenuCategory from '../HomeScreenComponents/Category';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const Recommender: FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const [query, setQuery] = useState<any>();
  const [objective, setObjective] = useState('');
  const [step, setStep] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [maxSteps, setMaxSteps] = useState(2);
  const [l, setL] = useState('');
  const [a, setA] = useState('');
  const [i, setI] = useState('');
  const [h, setH] = useState('');
  const [t, setT] = useState('');
  const [s, setS] = useState('');

  useEffect(() => {
    if (user.thrombosis === true || user.heart_diseases === true) {
      setQuery(recomendador_safe);
    } else {
      setQuery(recomendador);
    }
  }, []);

  const updateQuery = (e: any) => {
    let varMax = 0;
    if (e.target.value[0] === 'l') {
      setL(e.target.value);
      setQuery(query[e.target.value]);
    } else if (e.target.value[0] === 'a') {
      setA(e.target.value);
      setQuery(query[e.target.value]);
    } else if (e.target.value[0] === 'i') {
      if (a === 'a1' && 'i2' === e.target.value && l === 'l1') {
        console.log('ahora!');
        varMax += 1;
      } else if (a === 'a1' && ['i2', 'i3'].includes(e.target.value) === true && l !== 'l5' && l !== 'l1') {
        console.log('ahora2!');
        varMax += 1;
      }
      if (t === 't1' || ['l2', 'l3', 'l4', 'l8', 'l9'].includes(l) === true) {
        varMax += 1;
      }
      setMaxSteps((oldMaxSteps) => oldMaxSteps + varMax);
      setI(e.target.value);
    } else if (e.target.value[0] === 't') {
      if (e.target.value[0] === 't1' || ['l2', 'l3', 'l4', 'l8', 'l9'].includes(l) === true) {
        if (maxSteps < 6) {
          varMax += 1;
        }
      }
      setMaxSteps((oldMaxSteps) => oldMaxSteps + varMax);
      setT(e.target.value);
    } else if (e.target.value[0] === 'h') {
      setH(e.target.value);
    } else if (e.target.value[0] === 's') {
      if ('s1' === e.target.value) {
        varMax -= 1;
        setMaxSteps((oldMaxSteps) => oldMaxSteps - 1);
      }
      setS(e.target.value);
      setQuery(query[e.target.value]);
    } else {
      if (e.target.id === 'molestias') {
        setMaxSteps(4);
      } else if (e.target.id === 'cansancio') {
        setMaxSteps(3);
      }
      setQuery(query[e.target.id]);
      setObjective(e.target.id);
    }
    console.log(query[e.target.value], maxSteps, varMax, maxSteps + varMax);
    if (step + 1 >= maxSteps + varMax) {
      getRecommendation(e.target.value);
      console.log('fin');
    } else {
      setStep(step + 1);
    }
  };

  const getRecommendation = (value: string) => {
    const i_r = value[0] === 'i' ? value : i;
    const h_r = value[0] === 'h' ? value : h;
    const t_r = value[0] === 't' ? value : t;
    if (t_r !== '' && h_r !== '' && h_r !== 'h0') {
      try {
        if (h_r === 'h1' && t_r === '' && query[`${i_r}${h_r}t0`] !== undefined) {
          setQuery(query[`${i_r}${h_r}t0`]);
        } else if (query[`${i_r}${h_r}${t_r}`] !== undefined) {
          setQuery(query[`${i_r}${h_r}${t_r}`]);
        }
      } catch (error) {
        setQuery(query[i_r]);
      }
    } else if (h_r === 'h1' && query[`${i_r}h1t0`] !== undefined) {
      setQuery(query[`${i_r}h1t0`]);
    } else if (i_r !== '' && query[i_r] !== undefined) {
      setQuery(query[i_r]);
      console.log(query[i_r]);
    }
    setShowResults(true);
    console.log('results');
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
    if (step + 1 >= maxSteps) {
      getRecommendation('');
    }
  };

  const reset = () => {
    setQuery(recomendador);
    setObjective('');
    setStep(0);
    setMaxSteps(2);
    setShowResults(false);
    setL('');
    setA('');
    setI('');
    setH('');
    setT('');
    setS('');
  };

  return (
    <Box>
      <Box sx={{ maxWidth: { xs: '300px', sm: '100%' }, marginX: '10vw' }}>
        <Stack justifyContent="flex-start" alignItems="flex-start" direction="row" useFlexGap display="inline-block">
          <Chip
            sx={{ margin: '5px', fontWeight: 'bold', color: '#1b4ed8' }}
            key={`chip-reboot`}
            label="REINICIAR"
            onClick={reset}
            icon={<RestartAltIcon color="primary" />}
          />
          {objective !== '' && <Chip color="info" sx={{ margin: '5px' }} key={`t-${objective}`} label={objective} />}
          {l !== '' && <Chip color="info" sx={{ margin: '5px' }} key={`t-${l}`} label={lx[l]} />}
          {a !== '' && <Chip color="info" sx={{ margin: '5px' }} key={`t-${a}`} label={ax[a]} />}
          {i !== '' && <Chip color="info" sx={{ margin: '5px' }} key={`t-${i}`} label={ix[i]} />}
          {h !== '' && <Chip color="info" sx={{ margin: '5px' }} key={`t-${h}`} label={hx[h]} />}
          {t !== '' && <Chip color="info" sx={{ margin: '5px' }} key={`t-${t}`} label={tx[t]} />}
        </Stack>
        {showResults === false && (
          <SwipeableViews disabled index={step}>
            <Box sx={{ maxWidth: { xs: '300px', sm: '100%' } }}>
              <h2>¿En qué podemos ayudarte?</h2>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                id="molestias">
                Molestia
              </Button>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                id="hinchazon">
                Hichazón
              </Button>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                id="cansancio">
                Cansancio
              </Button>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                id="tension">
                Tensión y estrés
              </Button>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                id="relajacion">
                Relajación
              </Button>
            </Box>
            <Box>
              {['molestias', 'hinchazon', 'tension'].includes(objective) === true && (
                <Box>
                  <h2>¿Cuál es la zona de molestia?</h2>
                  {objective !== 'hinchazon' && (
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      value="l1">
                      Cuello
                    </Button>
                  )}
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l2">
                    Hombro
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l3">
                    Brazo / Codo
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l4">
                    Muñeca / Mano
                  </Button>
                  {objective !== 'tension' && objective !== 'hinchazon' && (
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      value="l5">
                      Espalda alta
                    </Button>
                  )}
                  {objective !== 'hinchazon' && (
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      value="l6">
                      Espalda baja / Gluteo
                    </Button>
                  )}
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l7">
                    Pierna
                  </Button>
                  {objective !== 'tension' && (
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      value="l8">
                      Rodilla
                    </Button>
                  )}
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l9">
                    Tobillo / Pie
                  </Button>
                </Box>
              )}
              {objective === 'cansancio' && (
                <Box>
                  <h2>¿Qué tan cansado te sientes?</h2>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="s0">
                    Leve
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="s0">
                    Moderado
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="s0">
                    Alto
                  </Button>
                </Box>
              )}
              {objective === 'relajacion' && (
                <Box>
                  <h2>¿Has tenido un día estresante?</h2>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={handleNext}>
                    Si
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={handleNext}>
                    No
                  </Button>
                </Box>
              )}
            </Box>
            <Box>
              {objective === 'molestias' && (
                <Box>
                  <h2>Hace cuánto presenta la molestia</h2>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="a1">
                    Menos de una semana
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="a2">
                    Entre una semana y un mes
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="a3">
                    Entre uno y dos meses
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="a4">
                    Más de dos meses
                  </Button>
                </Box>
              )}
              {objective === 'cansancio' && s === 's0' && (
                <Box>
                  <h2>¿En qué zona del cuerpo?</h2>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l2">
                    Hombro
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l3">
                    Brazo / Codo
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l6">
                    Espalda baja / Gluteo
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="l7">
                    Pierna
                  </Button>
                </Box>
              )}
            </Box>
            <Box>
              <h2>¿Cuál es la intensidad de ese malestar?</h2>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                value="i1">
                Leve
              </Button>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                value="i2">
                Moderado
              </Button>
              <Button
                sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                variant="contained"
                onClick={(event: any) => updateQuery(event)}
                value="i3">
                Intenso
              </Button>
            </Box>
            <Box>
              {a === 'a1' && ['i2', 'i3'].includes(i) === true ? (
                <Box>
                  <h2>¿Golpe, caída o torcedura?</h2>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="t1">
                    Si
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="t0">
                    No
                  </Button>
                </Box>
              ) : (
                (t === 't1' || ['l2', 'l3', 'l4', 'l7', 'l8', 'l9'].includes(l) === true) && (
                  <Box>
                    <h2>¿Presencia de hinchazón?</h2>
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      value="h1">
                      Si
                    </Button>
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      value="h0">
                      No
                    </Button>
                  </Box>
                )
              )}
            </Box>
            <Box>
              {(t === 't1' || ['l2', 'l3', 'l4', 'l7', 'l8', 'l9'].includes(l) === true) && (
                <Box>
                  <h2>¿Presencia de hinchazón?</h2>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="h1">
                    Si
                  </Button>
                  <Button
                    sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '12px' }}
                    variant="contained"
                    onClick={(event: any) => updateQuery(event)}
                    value="h0">
                    No
                  </Button>
                </Box>
              )}
            </Box>
          </SwipeableViews>
        )}
        {showResults === false && (
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={step}
            backButton={<Button sx={{ color: '#bbb', fontWeight: 'bold' }} size="small" aria-label="back"></Button>}
            nextButton={<Button sx={{ color: '#bbb', fontWeight: 'bold' }} size="small" aria-label="back"></Button>}
            sx={{ borderRadius: '0 0 25px 25px', width: { xs: '300px', sm: '100%' }, maxWidth: '92vw' }}
          />
        )}
      </Box>
      <Box>
        {showResults === true && (
          <MenuCategory specs={{ name: 'Recomendado', protocolIds: query.map((p: any) => p.id) }} />
        )}
      </Box>
    </Box>
  );
};

export default Recommender;
